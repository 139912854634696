import { FIELD_TYPES } from "constants/template-field-type";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Tier descriptions configuration",
    fields: {
        free: {
            type: FIELD_TYPES.TEXT,
            label: "Free tier description",
            translatable: true
        },
        basic: {
            type: FIELD_TYPES.TEXT,
            label: "Basic tier description",
            translatable: true
        },
        standard: {
            type: FIELD_TYPES.TEXT,
            label: "Standard tier description",
            translatable: true
        },
        pro: {
            type: FIELD_TYPES.TEXT,
            label: "Pro tier description",
            translatable: true
        },
        enterprise: {
            type: FIELD_TYPES.TEXT,
            label: "Enterprise tier description",
            translatable: true
        }
    }
};
