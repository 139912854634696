import { FIELD_TYPES } from "constants/template-field-type";
import PricesAndFeaturesCardsPricing from "../prices-and-features-cards-pricing/index.js";
import FeatureTablePricing from "../features-table-pricing/index.js";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Multi Products Pricing",
    fields: {
        pricesAndFeaturesCardsPricing: PricesAndFeaturesCardsPricing.PricesAndFeaturesCardsPricingConfig,
        featureTablePricing: FeatureTablePricing.FeaturesTablePricingConfig
    }
};
