const SEATS_OPTIONS = {
    3: 3,
    5: 5,
    10: 10,
    15: 15,
    20: 20,
    25: 25,
    30: 30,
    40: 40,
    50: 50,
    100: 100,
    "200+": 200
};
module.exports = {
    SEATS_OPTIONS
};
