// plop_marker:layouts_import
import MultiProductsPricing from "./multi-products-pricing/index.js";
import FeaturesTablePricing from "./features-table-pricing/index.js";
import PricesAndFeaturesCardsPricing from "./prices-and-features-cards-pricing/index.js";
import FrequentlyAskedQuestionsPricing from "./frequently-asked-questions-pricing/index.js";
export default {
    // plop_marker:layouts_add_layout
    MultiProductsPricing,
    FeaturesTablePricing,
    PricesAndFeaturesCardsPricing,
    FrequentlyAskedQuestionsPricing
};
